// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as PaymentBody$OrcaPaymentPage from "../Utilities/PaymentBody.bs.js";
import * as PaymentType$OrcaPaymentPage from "../Types/PaymentType.bs.js";
import * as PaymentUtils$OrcaPaymentPage from "../Utilities/PaymentUtils.bs.js";
import * as SessionsType$OrcaPaymentPage from "../Types/SessionsType.bs.js";
import * as ApplePayTypes$OrcaPaymentPage from "../Types/ApplePayTypes.bs.js";
import * as GooglePayType$OrcaPaymentPage from "../Types/GooglePayType.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "../Utilities/PaymentHelpers.bs.js";
import * as ApplePayHelpers$OrcaPaymentPage from "../Utilities/ApplePayHelpers.bs.js";
import * as GooglePayHelpers$OrcaPaymentPage from "../Utilities/GooglePayHelpers.bs.js";

function getCustomerSavedPaymentMethods(clientSecret, publishableKey, endpoint, logger, customPodUri) {
  var applePaySessionRef = {
    contents: null
  };
  return Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.fetchCustomerPaymentMethodList(clientSecret, publishableKey, endpoint, logger, customPodUri, true).then(function (customerDetails) {
                  var gPayClient = new google.payments.api.PaymentsClient({
                        environment: publishableKey.startsWith("pk_prd_") ? "PRODUCTION" : "TEST"
                      });
                  var customerDetailsDict = Core__Option.getOr(Core__JSON.Decode.object(customerDetails), {});
                  var match = PaymentType$OrcaPaymentPage.itemToCustomerObjMapper(customerDetailsDict);
                  var isGuestCustomer = match[1];
                  var customerPaymentMethods = match[0];
                  customerPaymentMethods.sort(function (a, b) {
                        return Utils$OrcaPaymentPage.compareLogic(a.lastUsedAt, b.lastUsedAt);
                      });
                  var customerPaymentMethodsRef = {
                    contents: customerPaymentMethods
                  };
                  var applePayTokenRef = {
                    contents: ApplePayTypes$OrcaPaymentPage.defaultHeadlessApplePayToken
                  };
                  var googlePayTokenRef = {
                    contents: null
                  };
                  var isApplePayPresent = Core__Option.isSome(customerPaymentMethods.find(function (customerPaymentMethod) {
                            return customerPaymentMethod.paymentMethodType === "apple_pay";
                          }));
                  var isGooglePayPresent = Core__Option.isSome(customerPaymentMethods.find(function (customerPaymentMethod) {
                            return customerPaymentMethod.paymentMethodType === "google_pay";
                          }));
                  var canMakePayments;
                  try {
                    var session = window.ApplePaySession;
                    canMakePayments = (session == null) ? false : session.canMakePayments();
                  }
                  catch (exn){
                    canMakePayments = false;
                  }
                  var customerDefaultPaymentMethod = customerPaymentMethods.filter(function (customerPaymentMethod) {
                          return customerPaymentMethod.defaultPaymentMethodSet;
                        })[0];
                  var getCustomerDefaultSavedPaymentMethodData = function () {
                    if (customerDefaultPaymentMethod !== undefined) {
                      return customerDefaultPaymentMethod;
                    } else {
                      return Utils$OrcaPaymentPage.handleFailureResponse("There is no default saved payment method data for this customer.", "no_data");
                    }
                  };
                  var getCustomerLastUsedPaymentMethodData = function () {
                    var lastUsedPaymentMethod = customerPaymentMethodsRef.contents[0];
                    if (lastUsedPaymentMethod !== undefined) {
                      return lastUsedPaymentMethod;
                    } else {
                      return Utils$OrcaPaymentPage.handleFailureResponse("No recent payments found for this customer.", "no_data");
                    }
                  };
                  var confirmWithCustomerDefaultPaymentMethod = function (payload) {
                    if (customerDefaultPaymentMethod === undefined) {
                      return Promise.resolve(Utils$OrcaPaymentPage.handleFailureResponse("There is no default saved payment method data for this customer.", "no_data"));
                    }
                    var paymentToken = customerDefaultPaymentMethod.paymentToken;
                    var paymentMethod = customerDefaultPaymentMethod.paymentMethod;
                    var paymentMethodType = Core__Option.getOr(customerDefaultPaymentMethod.paymentMethodType, "");
                    var paymentType = PaymentHelpers$OrcaPaymentPage.getPaymentType(paymentMethodType);
                    var body = [
                      [
                        "payment_method",
                        paymentMethod
                      ],
                      [
                        "payment_token",
                        paymentToken
                      ]
                    ];
                    if (paymentMethodType !== "") {
                      body.push([
                            "payment_method_type",
                            paymentMethodType
                          ]);
                    }
                    return PaymentHelpers$OrcaPaymentPage.paymentIntentForPaymentSession(body, paymentType, payload, publishableKey, clientSecret, logger, customPodUri);
                  };
                  var confirmWithLastUsedPaymentMethod = function (payload) {
                    var lastUsedPaymentMethod = customerPaymentMethodsRef.contents[0];
                    if (lastUsedPaymentMethod === undefined) {
                      return Promise.resolve(Utils$OrcaPaymentPage.handleFailureResponse("No recent payments found for this customer.", "no_data"));
                    }
                    if (lastUsedPaymentMethod.paymentMethodType === "apple_pay") {
                      return new Promise((function (resolve, param) {
                                    var processPayment = function (payment) {
                                      var token = payment.token;
                                      var billingContactDict = Utils$OrcaPaymentPage.getDictFromJson(payment.billingContact);
                                      var shippingContactDict = Utils$OrcaPaymentPage.getDictFromJson(payment.shippingContact);
                                      var completeApplePayPayment = function (stateJson) {
                                        var applePayBody = ApplePayHelpers$OrcaPaymentPage.getApplePayFromResponse(token, billingContactDict, shippingContactDict, undefined, stateJson, [], true, undefined);
                                        var requestBody = PaymentUtils$OrcaPaymentPage.appendedCustomerAcceptance(isGuestCustomer, "NONE", applePayBody);
                                        var paymentMethodType = Core__Option.getOr(lastUsedPaymentMethod.paymentMethodType, "");
                                        var paymentType = PaymentHelpers$OrcaPaymentPage.getPaymentType(paymentMethodType);
                                        return PaymentHelpers$OrcaPaymentPage.paymentIntentForPaymentSession(requestBody, paymentType, payload, publishableKey, clientSecret, logger, customPodUri).then(function (val) {
                                                    resolve(val);
                                                    return Promise.resolve();
                                                  });
                                      };
                                      Core__Promise.$$catch(PaymentUtils$OrcaPaymentPage.getStateJson().then(function (stateJson) {
                                                logger.setLogInfo("States Loaded", undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
                                                return completeApplePayPayment(stateJson);
                                              }), (function (err) {
                                              var value = "Error Loading States : " + JSON.stringify(err);
                                              logger.setLogInfo(value, undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
                                              return completeApplePayPayment(null);
                                            }));
                                    };
                                    ApplePayHelpers$OrcaPaymentPage.startApplePaySession(applePayTokenRef.contents.paymentRequestData, applePaySessionRef, applePayTokenRef.contents.sessionTokenData, logger, undefined, processPayment, undefined, clientSecret, publishableKey, undefined);
                                  }));
                    }
                    if (lastUsedPaymentMethod.paymentMethodType === "google_pay") {
                      var paymentDataRequest = googlePayTokenRef.contents;
                      return Core__Promise.$$catch(gPayClient.loadPaymentData(paymentDataRequest).then(function (json) {
                                      logger.setLogInfo("Payment Data Filled: New Payment Method", undefined, "PAYMENT_DATA_FILLED", undefined, undefined, undefined, undefined, "GOOGLE_PAY");
                                      var completeGooglePayPayment = function (stateJson) {
                                        var body = GooglePayHelpers$OrcaPaymentPage.getGooglePayBodyFromResponse(json, isGuestCustomer, undefined, [], undefined, stateJson, true, undefined);
                                        var paymentMethodType = Core__Option.getOr(lastUsedPaymentMethod.paymentMethodType, "");
                                        var paymentType = PaymentHelpers$OrcaPaymentPage.getPaymentType(paymentMethodType);
                                        return PaymentHelpers$OrcaPaymentPage.paymentIntentForPaymentSession(body, paymentType, payload, publishableKey, clientSecret, logger, customPodUri);
                                      };
                                      return Core__Promise.$$catch(PaymentUtils$OrcaPaymentPage.getStateJson().then(function (stateJson) {
                                                      logger.setLogInfo("States Loaded", undefined, "GOOGLE_PAY_FLOW", undefined, undefined, undefined, undefined, "GOOGLE_PAY");
                                                      return completeGooglePayPayment(stateJson);
                                                    }), (function (err) {
                                                    var value = "Error Loading States : " + JSON.stringify(err);
                                                    logger.setLogInfo(value, undefined, "GOOGLE_PAY_FLOW", undefined, undefined, undefined, undefined, "GOOGLE_PAY");
                                                    return completeGooglePayPayment(null);
                                                  }));
                                    }), (function (err) {
                                    logger.setLogInfo(JSON.stringify(err), undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "DEBUG", undefined, "GOOGLE_PAY");
                                    return Promise.resolve(Utils$OrcaPaymentPage.handleFailureResponse(JSON.stringify(err), "google_pay"));
                                  }));
                    }
                    var paymentToken = lastUsedPaymentMethod.paymentToken;
                    var paymentMethod = lastUsedPaymentMethod.paymentMethod;
                    var paymentMethodType = Core__Option.getOr(lastUsedPaymentMethod.paymentMethodType, "");
                    var paymentType = PaymentHelpers$OrcaPaymentPage.getPaymentType(paymentMethodType);
                    var isCustomerAcceptanceRequired = !lastUsedPaymentMethod.recurringEnabled;
                    var body = [
                      [
                        "payment_method",
                        paymentMethod
                      ],
                      [
                        "payment_token",
                        paymentToken
                      ]
                    ];
                    if (isCustomerAcceptanceRequired) {
                      body.push([
                            "customer_acceptance",
                            PaymentBody$OrcaPaymentPage.customerAcceptanceBody
                          ]);
                    }
                    if (paymentMethodType !== "") {
                      body.push([
                            "payment_method_type",
                            paymentMethodType
                          ]);
                    }
                    return PaymentHelpers$OrcaPaymentPage.paymentIntentForPaymentSession(body, paymentType, payload, publishableKey, clientSecret, logger, customPodUri);
                  };
                  var updateCustomerPaymentMethodsRef = function (isFilterApplePayOpt, isFilterGooglePayOpt) {
                    var isFilterApplePay = isFilterApplePayOpt !== undefined ? isFilterApplePayOpt : false;
                    var isFilterGooglePay = isFilterGooglePayOpt !== undefined ? isFilterGooglePayOpt : false;
                    var filterArray = [];
                    if (isFilterApplePay) {
                      filterArray.push("apple_pay");
                    }
                    if (isFilterGooglePay) {
                      filterArray.push("google_pay");
                    }
                    var updatedCustomerDetails = customerPaymentMethodsRef.contents.filter(function (customerPaymentMethod) {
                          return !filterArray.includes(Core__Option.getOr(customerPaymentMethod.paymentMethodType, ""));
                        });
                    customerPaymentMethodsRef.contents = updatedCustomerDetails;
                  };
                  if (isApplePayPresent && canMakePayments || isGooglePayPresent) {
                    return Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.fetchSessions(clientSecret, publishableKey, undefined, undefined, logger, customPodUri, endpoint, undefined, undefined).then(function (sessionDetails) {
                                    var componentName = "headless";
                                    var dict = Utils$OrcaPaymentPage.getDictFromJson(sessionDetails);
                                    var sessionObj = SessionsType$OrcaPaymentPage.itemToObjMapper(dict, "Others");
                                    var applePaySessionObj = SessionsType$OrcaPaymentPage.itemToObjMapper(dict, "ApplePayObject");
                                    var applePayToken = SessionsType$OrcaPaymentPage.getPaymentSessionObj(applePaySessionObj.sessionsToken, "ApplePay");
                                    var gPayToken = SessionsType$OrcaPaymentPage.getPaymentSessionObj(sessionObj.sessionsToken, "Gpay");
                                    var gPayTokenObj;
                                    switch (gPayToken.TAG) {
                                      case "ApplePayTokenOptional" :
                                      case "GooglePayThirdPartyTokenOptional" :
                                          gPayTokenObj = SessionsType$OrcaPaymentPage.defaultToken;
                                          break;
                                      case "OtherTokenOptional" :
                                          gPayTokenObj = gPayToken._0;
                                          break;
                                      
                                    }
                                    var gPayobj = gPayTokenObj !== undefined ? gPayTokenObj : SessionsType$OrcaPaymentPage.defaultToken;
                                    var payRequest = Object.assign({}, GooglePayType$OrcaPaymentPage.baseRequest, {
                                          allowedPaymentMethods: Utils$OrcaPaymentPage.arrayJsonToCamelCase(gPayobj.allowed_payment_methods)
                                        });
                                    var isGooglePayReadyPromise;
                                    try {
                                      isGooglePayReadyPromise = Core__Promise.$$catch(gPayClient.isReadyToPay(payRequest).then(function (res) {
                                                var dict = Utils$OrcaPaymentPage.getDictFromJson(res);
                                                return Promise.resolve(Utils$OrcaPaymentPage.getBool(dict, "result", false));
                                              }), (function (err) {
                                              logger.setLogInfo(JSON.stringify(err), undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "DEBUG", undefined, "GOOGLE_PAY");
                                              return Promise.resolve(false);
                                            }));
                                    }
                                    catch (raw_exn){
                                      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                                      logger.setLogInfo(JSON.stringify(exn), undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "DEBUG", undefined, "GOOGLE_PAY");
                                      isGooglePayReadyPromise = Promise.resolve(false);
                                    }
                                    isGooglePayReadyPromise.then(function (isGooglePayReady) {
                                          if (isGooglePayReady) {
                                            var paymentDataRequest = GooglePayType$OrcaPaymentPage.getPaymentDataFromSession(gPayTokenObj, componentName);
                                            googlePayTokenRef.contents = paymentDataRequest;
                                          } else {
                                            updateCustomerPaymentMethodsRef(undefined, true);
                                          }
                                          return Promise.resolve();
                                        });
                                    switch (applePayToken.TAG) {
                                      case "ApplePayTokenOptional" :
                                          var optToken = applePayToken._0;
                                          var paymentRequest = ApplePayTypes$OrcaPaymentPage.getPaymentRequestFromSession(optToken, componentName);
                                          applePayTokenRef.contents = {
                                            paymentRequestData: paymentRequest,
                                            sessionTokenData: optToken
                                          };
                                          break;
                                      case "GooglePayThirdPartyTokenOptional" :
                                      case "OtherTokenOptional" :
                                          updateCustomerPaymentMethodsRef(true, undefined);
                                          break;
                                      
                                    }
                                    return Promise.resolve({
                                                getCustomerDefaultSavedPaymentMethodData: getCustomerDefaultSavedPaymentMethodData,
                                                getCustomerLastUsedPaymentMethodData: getCustomerLastUsedPaymentMethodData,
                                                confirmWithCustomerDefaultPaymentMethod: confirmWithCustomerDefaultPaymentMethod,
                                                confirmWithLastUsedPaymentMethod: confirmWithLastUsedPaymentMethod
                                              });
                                  }), (function (param) {
                                  updateCustomerPaymentMethodsRef(true, true);
                                  return Promise.resolve({
                                              getCustomerDefaultSavedPaymentMethodData: getCustomerDefaultSavedPaymentMethodData,
                                              getCustomerLastUsedPaymentMethodData: getCustomerLastUsedPaymentMethodData,
                                              confirmWithCustomerDefaultPaymentMethod: confirmWithCustomerDefaultPaymentMethod,
                                              confirmWithLastUsedPaymentMethod: confirmWithLastUsedPaymentMethod
                                            });
                                }));
                  } else {
                    updateCustomerPaymentMethodsRef(true, true);
                    return Promise.resolve({
                                getCustomerDefaultSavedPaymentMethodData: getCustomerDefaultSavedPaymentMethodData,
                                getCustomerLastUsedPaymentMethodData: getCustomerLastUsedPaymentMethodData,
                                confirmWithCustomerDefaultPaymentMethod: confirmWithCustomerDefaultPaymentMethod,
                                confirmWithLastUsedPaymentMethod: confirmWithLastUsedPaymentMethod
                              });
                  }
                }), (function (err) {
                var exceptionMessage = JSON.stringify(Utils$OrcaPaymentPage.formatException(err));
                return Promise.resolve(Utils$OrcaPaymentPage.handleFailureResponse(exceptionMessage, "server_error"));
              }));
}

function getPaymentManagementMethods(ephemeralKey, logger, customPodUri, endpoint) {
  var getSavedPaymentManagementMethodsList = function (param) {
    return Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.fetchSavedPaymentMethodList(ephemeralKey, endpoint, logger, customPodUri, undefined).then(function (response) {
                    return Promise.resolve(response);
                  }), (function (err) {
                  var exceptionMessage = JSON.stringify(Utils$OrcaPaymentPage.formatException(err));
                  return Promise.resolve(Utils$OrcaPaymentPage.handleFailureResponse(exceptionMessage, "server_error"));
                }));
  };
  var deleteSavedPaymentMethod = function (paymentMethodId) {
    return Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.deletePaymentMethod(ephemeralKey, Core__Option.getOr(Core__JSON.Decode.string(paymentMethodId), ""), logger, customPodUri).then(function (response) {
                    return Promise.resolve(response);
                  }), (function (err) {
                  var exceptionMessage = JSON.stringify(Utils$OrcaPaymentPage.formatException(err));
                  return Promise.resolve(Utils$OrcaPaymentPage.handleFailureResponse(exceptionMessage, "server_error"));
                }));
  };
  return Promise.resolve({
              getSavedPaymentManagementMethodsList: getSavedPaymentManagementMethodsList,
              deleteSavedPaymentMethod: deleteSavedPaymentMethod
            });
}

export {
  getCustomerSavedPaymentMethods ,
  getPaymentManagementMethods ,
}
/* Utils-OrcaPaymentPage Not a pure module */
