// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/src/Core__Float.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as CardThemeType$OrcaPaymentPage from "../Types/CardThemeType.bs.js";

function eventNameToStrMapper(eventName) {
  switch (eventName) {
    case "APP_RENDERED" :
        return "APP_RENDERED";
    case "PAYMENT_METHOD_CHANGED" :
        return "PAYMENT_METHOD_CHANGED";
    case "PAYMENT_DATA_FILLED" :
        return "PAYMENT_DATA_FILLED";
    case "PAYMENT_ATTEMPT" :
        return "PAYMENT_ATTEMPT";
    case "PAYMENT_SUCCESS" :
        return "PAYMENT_SUCCESS";
    case "PAYMENT_FAILED" :
        return "PAYMENT_FAILED";
    case "INPUT_FIELD_CHANGED" :
        return "INPUT_FIELD_CHANGED";
    case "RETRIEVE_CALL_INIT" :
        return "RETRIEVE_CALL_INIT";
    case "RETRIEVE_CALL" :
        return "RETRIEVE_CALL";
    case "AUTHENTICATION_CALL_INIT" :
        return "AUTHENTICATION_CALL_INIT";
    case "AUTHENTICATION_CALL" :
        return "AUTHENTICATION_CALL";
    case "CONFIRM_CALL_INIT" :
        return "CONFIRM_CALL_INIT";
    case "CONFIRM_CALL" :
        return "CONFIRM_CALL";
    case "CONFIRM_PAYOUT_CALL_INIT" :
        return "CONFIRM_PAYOUT_CALL_INIT";
    case "CONFIRM_PAYOUT_CALL" :
        return "CONFIRM_PAYOUT_CALL";
    case "SESSIONS_CALL_INIT" :
        return "SESSIONS_CALL_INIT";
    case "SESSIONS_CALL" :
        return "SESSIONS_CALL";
    case "PAYMENT_METHODS_CALL_INIT" :
        return "PAYMENT_METHODS_CALL_INIT";
    case "SAVED_PAYMENT_METHODS_CALL_INIT" :
        return "SAVED_PAYMENT_METHODS_CALL_INIT";
    case "PAYMENT_METHODS_CALL" :
        return "PAYMENT_METHODS_CALL";
    case "SAVED_PAYMENT_METHODS_CALL" :
        return "SAVED_PAYMENT_METHODS_CALL";
    case "CUSTOMER_PAYMENT_METHODS_CALL_INIT" :
        return "CUSTOMER_PAYMENT_METHODS_CALL_INIT";
    case "CUSTOMER_PAYMENT_METHODS_CALL" :
        return "CUSTOMER_PAYMENT_METHODS_CALL";
    case "CREATE_CUSTOMER_PAYMENT_METHODS_CALL_INIT" :
        return "CREATE_CUSTOMER_PAYMENT_METHODS_CALL_INIT";
    case "CREATE_CUSTOMER_PAYMENT_METHODS_CALL" :
        return "CREATE_CUSTOMER_PAYMENT_METHODS_CALL";
    case "TRUSTPAY_SCRIPT" :
        return "TRUSTPAY_SCRIPT";
    case "PM_AUTH_CONNECTOR_SCRIPT" :
        return "PM_AUTH_CONNECTOR_SCRIPT";
    case "GOOGLE_PAY_SCRIPT" :
        return "GOOGLE_PAY_SCRIPT";
    case "APPLE_PAY_FLOW" :
        return "APPLE_PAY_FLOW";
    case "GOOGLE_PAY_FLOW" :
        return "GOOGLE_PAY_FLOW";
    case "PAYPAL_FLOW" :
        return "PAYPAL_FLOW";
    case "PAYPAL_SDK_FLOW" :
        return "PAYPAL_SDK_FLOW";
    case "APP_INITIATED" :
        return "APP_INITIATED";
    case "APP_REINITIATED" :
        return "APP_REINITIATED";
    case "LOG_INITIATED" :
        return "LOG_INITIATED";
    case "LOADER_CALLED" :
        return "LOADER_CALLED";
    case "ORCA_ELEMENTS_CALLED" :
        return "ORCA_ELEMENTS_CALLED";
    case "PAYMENT_OPTIONS_PROVIDED" :
        return "PAYMENT_OPTIONS_PROVIDED";
    case "BLUR" :
        return "BLUR";
    case "FOCUS" :
        return "FOCUS";
    case "CLEAR" :
        return "CLEAR";
    case "CONFIRM_PAYMENT" :
        return "CONFIRM_PAYMENT";
    case "CONFIRM_CARD_PAYMENT" :
        return "CONFIRM_CARD_PAYMENT";
    case "SDK_CRASH" :
        return "SDK_CRASH";
    case "INVALID_PK" :
        return "INVALID_PK";
    case "DEPRECATED_LOADSTRIPE" :
        return "DEPRECATED_LOADSTRIPE";
    case "REQUIRED_PARAMETER" :
        return "REQUIRED_PARAMETER";
    case "UNKNOWN_KEY" :
        return "UNKNOWN_KEY";
    case "UNKNOWN_VALUE" :
        return "UNKNOWN_VALUE";
    case "TYPE_BOOL_ERROR" :
        return "TYPE_BOOL_ERROR";
    case "TYPE_INT_ERROR" :
        return "TYPE_INT_ERROR";
    case "TYPE_STRING_ERROR" :
        return "TYPE_STRING_ERROR";
    case "INVALID_FORMAT" :
        return "INVALID_FORMAT";
    case "SDK_CONNECTOR_WARNING" :
        return "SDK_CONNECTOR_WARNING";
    case "VALUE_OUT_OF_RANGE" :
        return "VALUE_OUT_OF_RANGE";
    case "HTTP_NOT_ALLOWED" :
        return "HTTP_NOT_ALLOWED";
    case "INTERNAL_API_DOWN" :
        return "INTERNAL_API_DOWN";
    case "REDIRECTING_USER" :
        return "REDIRECTING_USER";
    case "DISPLAY_BANK_TRANSFER_INFO_PAGE" :
        return "DISPLAY_BANK_TRANSFER_INFO_PAGE";
    case "DISPLAY_QR_CODE_INFO_PAGE" :
        return "DISPLAY_QR_CODE_INFO_PAGE";
    case "DISPLAY_VOUCHER" :
        return "DISPLAY_VOUCHER";
    case "DISPLAY_THREE_DS_SDK" :
        return "DISPLAY_THREE_DS_SDK";
    case "THREE_DS_METHOD" :
        return "THREE_DS_METHOD";
    case "THREE_DS_METHOD_RESULT" :
        return "THREE_DS_METHOD_RESULT";
    case "PAYMENT_METHODS_RESPONSE" :
        return "PAYMENT_METHODS_RESPONSE";
    case "LOADER_CHANGED" :
        return "LOADER_CHANGED";
    case "PAYMENT_SESSION_INITIATED" :
        return "PAYMENT_SESSION_INITIATED";
    case "POLL_STATUS_CALL_INIT" :
        return "POLL_STATUS_CALL_INIT";
    case "POLL_STATUS_CALL" :
        return "POLL_STATUS_CALL";
    case "COMPLETE_AUTHORIZE_CALL_INIT" :
        return "COMPLETE_AUTHORIZE_CALL_INIT";
    case "COMPLETE_AUTHORIZE_CALL" :
        return "COMPLETE_AUTHORIZE_CALL";
    case "PLAID_SDK" :
        return "PLAID_SDK";
    case "PAYMENT_METHODS_AUTH_EXCHANGE_CALL_INIT" :
        return "PAYMENT_METHODS_AUTH_EXCHANGE_CALL_INIT";
    case "PAYMENT_METHODS_AUTH_EXCHANGE_CALL" :
        return "PAYMENT_METHODS_AUTH_EXCHANGE_CALL";
    case "PAYMENT_METHODS_AUTH_LINK_CALL_INIT" :
        return "PAYMENT_METHODS_AUTH_LINK_CALL_INIT";
    case "PAYMENT_METHODS_AUTH_LINK_CALL" :
        return "PAYMENT_METHODS_AUTH_LINK_CALL";
    case "PAYMENT_MANAGEMENT_ELEMENTS_CALLED" :
        return "PAYMENT_MANAGEMENT_ELEMENTS_CALLED";
    case "DELETE_SAVED_PAYMENT_METHOD" :
        return "DELETE_SAVED_PAYMENT_METHOD";
    case "DELETE_PAYMENT_METHODS_CALL_INIT" :
        return "DELETE_PAYMENT_METHODS_CALL_INIT";
    case "DELETE_PAYMENT_METHODS_CALL" :
        return "DELETE_PAYMENT_METHODS_CALL";
    case "EXTERNAL_TAX_CALCULATION" :
        return "EXTERNAL_TAX_CALCULATION";
    
  }
}

function getPaymentId(clientSecret) {
  return Core__Option.getOr(clientSecret.split("_secret_")[0], "");
}

function convertToScreamingSnakeCase(text) {
  return text.trim().replace(/ /g, "_").toUpperCase();
}

function toSnakeCaseWithSeparator(str, separator) {
  return str.replace(/[A-Z]/g, (function (letter, param, param$1) {
                return separator + letter.toLowerCase();
              }));
}

function logInfo(log) {
  if (Window$OrcaPaymentPage.isProd) {
    return ;
  } else {
    return log;
  }
}

function defaultLoggerConfig_setLogInfo(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  
}

function defaultLoggerConfig_setLogError(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  
}

function defaultLoggerConfig_setLogApi(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  
}

function defaultLoggerConfig_setLogInitiated() {
  
}

function defaultLoggerConfig_setConfirmPaymentValue(param) {
  return {};
}

function defaultLoggerConfig_sendLogs() {
  
}

function defaultLoggerConfig_setSessionId(_x) {
  
}

function defaultLoggerConfig_setClientSecret(_x) {
  
}

function defaultLoggerConfig_setMerchantId(_x) {
  
}

function defaultLoggerConfig_setMetadata(_x) {
  
}

function defaultLoggerConfig_setSource(_x) {
  
}

function defaultLoggerConfig_setEphemeralKey(_x) {
  
}

var defaultLoggerConfig = {
  setLogInfo: defaultLoggerConfig_setLogInfo,
  setLogError: defaultLoggerConfig_setLogError,
  setLogApi: defaultLoggerConfig_setLogApi,
  setLogInitiated: defaultLoggerConfig_setLogInitiated,
  setConfirmPaymentValue: defaultLoggerConfig_setConfirmPaymentValue,
  sendLogs: defaultLoggerConfig_sendLogs,
  setSessionId: defaultLoggerConfig_setSessionId,
  setClientSecret: defaultLoggerConfig_setClientSecret,
  setMerchantId: defaultLoggerConfig_setMerchantId,
  setMetadata: defaultLoggerConfig_setMetadata,
  setSource: defaultLoggerConfig_setSource,
  setEphemeralKey: defaultLoggerConfig_setEphemeralKey
};

function logFileToObj(logFile) {
  var match = logFile.logType;
  var tmp;
  switch (match) {
    case "DEBUG" :
        tmp = "DEBUG";
        break;
    case "INFO" :
        tmp = "INFO";
        break;
    case "ERROR" :
        tmp = "ERROR";
        break;
    case "WARNING" :
        tmp = "WARNING";
        break;
    case "SILENT" :
        tmp = "SILENT";
        break;
    
  }
  var match$1 = logFile.category;
  var tmp$1;
  switch (match$1) {
    case "API" :
        tmp$1 = "API";
        break;
    case "USER_ERROR" :
        tmp$1 = "USER_ERROR";
        break;
    case "USER_EVENT" :
        tmp$1 = "USER_EVENT";
        break;
    case "MERCHANT_EVENT" :
        tmp$1 = "MERCHANT_EVENT";
        break;
    
  }
  return Object.fromEntries([
              [
                "timestamp",
                logFile.timestamp
              ],
              [
                "log_type",
                tmp
              ],
              [
                "component",
                "WEB"
              ],
              [
                "category",
                tmp$1
              ],
              [
                "source",
                convertToScreamingSnakeCase(logFile.source)
              ],
              [
                "version",
                logFile.version
              ],
              [
                "value",
                logFile.value
              ],
              [
                "internal_metadata",
                logFile.internalMetadata
              ],
              [
                "session_id",
                logFile.sessionId
              ],
              [
                "merchant_id",
                logFile.merchantId
              ],
              [
                "payment_id",
                logFile.paymentId
              ],
              [
                "app_id",
                logFile.appId
              ],
              [
                "platform",
                convertToScreamingSnakeCase(logFile.platform)
              ],
              [
                "user_agent",
                logFile.userAgent
              ],
              [
                "event_name",
                eventNameToStrMapper(logFile.eventName)
              ],
              [
                "browser_name",
                convertToScreamingSnakeCase(logFile.browserName)
              ],
              [
                "browser_version",
                logFile.browserVersion
              ],
              [
                "latency",
                logFile.latency
              ],
              [
                "first_event",
                logFile.firstEvent ? "true" : "false"
              ],
              [
                "payment_method",
                convertToScreamingSnakeCase(logFile.paymentMethod)
              ]
            ]);
}

function getRefFromOption(val) {
  var innerValue = Core__Option.getOr(val, "");
  return {
          contents: innerValue
        };
}

function getSourceString(source) {
  if (typeof source !== "object") {
    if (source === "Loader") {
      return "hyper_loader";
    } else {
      return "headless";
    }
  }
  var formattedPaymentMode = toSnakeCaseWithSeparator(CardThemeType$OrcaPaymentPage.getPaymentModeToStrMapper(source._0), "_");
  return "hyper" + formattedPaymentMode;
}

function findVersion(re, content) {
  var result = re.exec(content);
  if (result !== null) {
    return result;
  } else {
    return [];
  }
}

function browserDetect(content) {
  if (new RegExp("Instagram").test(content)) {
    var re = /Instagram\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a = Core__Option.getOr(findVersion(re, content)[1], null);
    var version = (a == null) ? "" : a;
    return "Instagram-" + version;
  }
  if (new RegExp("FBAV").test(content)) {
    var re$1 = /FBAV\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$1 = Core__Option.getOr(findVersion(re$1, content)[1], null);
    var version$1 = (a$1 == null) ? "" : a$1;
    return "Facebook-" + version$1;
  }
  if (new RegExp("Twitter").test(content)) {
    var re$2 = /iPhone\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$2 = Core__Option.getOr(findVersion(re$2, content)[1], null);
    var version$2 = (a$2 == null) ? "" : a$2;
    return "Twitter-" + version$2;
  }
  if (new RegExp("LinkedIn").test(content)) {
    var re$3 = /LinkedInApp\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$3 = Core__Option.getOr(findVersion(re$3, content)[1], null);
    var version$3 = (a$3 == null) ? "" : a$3;
    return "LinkedIn-" + version$3;
  }
  if (new RegExp("Edg").test(content)) {
    var re$4 = /Edg\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$4 = Core__Option.getOr(findVersion(re$4, content)[1], null);
    var version$4 = (a$4 == null) ? "" : a$4;
    return "Microsoft Edge-" + version$4;
  }
  if (new RegExp("Chrome").test(content)) {
    var re$5 = /Chrome\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$5 = Core__Option.getOr(findVersion(re$5, content)[1], null);
    var version$5 = (a$5 == null) ? "" : a$5;
    return "Chrome-" + version$5;
  }
  if (new RegExp("Safari").test(content)) {
    var re$6 = /Safari\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$6 = Core__Option.getOr(findVersion(re$6, content)[1], null);
    var version$6 = (a$6 == null) ? "" : a$6;
    return "Safari-" + version$6;
  }
  if (new RegExp("opera").test(content)) {
    var re$7 = /Opera\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$7 = Core__Option.getOr(findVersion(re$7, content)[1], null);
    var version$7 = (a$7 == null) ? "" : a$7;
    return "Opera-" + version$7;
  }
  if (!(new RegExp("Firefox").test(content) || new RegExp("fxios").test(content))) {
    return "Others-0";
  }
  if (new RegExp("Firefox").test(content)) {
    var re$8 = /Firefox\/([\d]+\.[\w]?\.?[\w]+)/ig;
    var a$8 = Core__Option.getOr(findVersion(re$8, content)[1], null);
    var version$8 = (a$8 == null) ? "" : a$8;
    return "Firefox-" + version$8;
  }
  var re$9 = /fxios\/([\d]+\.[\w]?\.?[\w]+)/ig;
  var a$9 = Core__Option.getOr(findVersion(re$9, content)[1], null);
  var version$9 = (a$9 == null) ? "" : a$9;
  return "Firefox-" + version$9;
}

var arrayOfNameAndVersion = browserDetect(window.navigator.userAgent).split("-");

function make(sessionId, source, clientSecret, merchantId, metadata, ephemeralKey) {
  var match = loggingLevel;
  var loggingLevel$1;
  switch (match) {
    case "DEBUG" :
        loggingLevel$1 = "DEBUG";
        break;
    case "ERROR" :
        loggingLevel$1 = "ERROR";
        break;
    case "INFO" :
        loggingLevel$1 = "INFO";
        break;
    case "WARNING" :
        loggingLevel$1 = "WARNING";
        break;
    default:
      loggingLevel$1 = "SILENT";
  }
  var mainLogFile = [];
  var sessionId$1 = getRefFromOption(sessionId);
  var setSessionId = function (value) {
    sessionId$1.contents = value;
  };
  var sourceString = getSourceString(source);
  var events = {
    contents: {}
  };
  var eventsCounter = {
    contents: {}
  };
  var timeOut = {
    contents: undefined
  };
  var merchantId$1 = getRefFromOption(merchantId);
  var setMerchantId = function (value) {
    merchantId$1.contents = value;
  };
  var metadata$1 = {
    contents: Core__Option.getOr(metadata, null)
  };
  var setMetadata = function (value) {
    metadata$1.contents = value;
  };
  var calculateAndUpdateCounterHook = function (eventName) {
    var num = eventsCounter.contents[eventName];
    var updatedCounter = num !== undefined ? num + 1 | 0 : 1;
    eventsCounter.contents[eventName] = updatedCounter;
    return updatedCounter;
  };
  var conditionalLogPush = function (log) {
    var maxLogsPushedPerEventName$1 = maxLogsPushedPerEventName;
    var match = log.eventName;
    var conditionalEventName;
    conditionalEventName = match === "INPUT_FIELD_CHANGED" ? log.value : "";
    var eventName = eventNameToStrMapper(log.eventName) + conditionalEventName;
    var counter = calculateAndUpdateCounterHook(eventName);
    if (!(enableLogging && counter <= maxLogsPushedPerEventName$1)) {
      return ;
    }
    switch (loggingLevel$1) {
      case "DEBUG" :
          ((function (__x) {
                  mainLogFile.push(__x);
                })(log));
          return ;
      case "INFO" :
          if ([
                "INFO",
                "WARNING",
                "ERROR"
              ].includes(log.logType)) {
            ((function (__x) {
                    mainLogFile.push(__x);
                  })(log));
            return ;
          } else {
            return ;
          }
      case "ERROR" :
          if (["ERROR"].includes(log.logType)) {
            ((function (__x) {
                    mainLogFile.push(__x);
                  })(log));
            return ;
          } else {
            return ;
          }
      case "WARNING" :
          if ([
                "WARNING",
                "ERROR"
              ].includes(log.logType)) {
            ((function (__x) {
                    mainLogFile.push(__x);
                  })(log));
            return ;
          } else {
            return ;
          }
      case "SILENT" :
          return ;
      
    }
  };
  var beaconApiCall = function (data) {
    if (data.length <= 0) {
      return ;
    }
    var logData = JSON.stringify(data.map(logFileToObj));
    navigator.sendBeacon(logEndpoint, logData);
  };
  var clientSecret$1 = getRefFromOption(clientSecret);
  var ephemeralKey$1 = getRefFromOption(ephemeralKey);
  var setClientSecret = function (value) {
    clientSecret$1.contents = value;
  };
  var setEphemeralKey = function (value) {
    ephemeralKey$1.contents = value;
  };
  var sourceRef = {
    contents: getSourceString(source)
  };
  var setSource = function (value) {
    sourceRef.contents = value;
  };
  var sendLogs = function () {
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    } else {
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    }
    beaconApiCall(mainLogFile);
    var len = mainLogFile.length;
    for(var _for = 0; _for < len; ++_for){
      mainLogFile.pop();
    }
  };
  var checkForPriorityEvents = function (arrayOfLogs) {
    var priorityEventNames = [
      "APP_RENDERED",
      "ORCA_ELEMENTS_CALLED",
      "PAYMENT_DATA_FILLED",
      "PAYMENT_ATTEMPT",
      "CONFIRM_CALL",
      "AUTHENTICATION_CALL",
      "THREE_DS_METHOD_RESULT",
      "SDK_CRASH",
      "REDIRECTING_USER",
      "DISPLAY_BANK_TRANSFER_INFO_PAGE",
      "DISPLAY_QR_CODE_INFO_PAGE",
      "DISPLAY_VOUCHER",
      "LOADER_CHANGED",
      "PAYMENT_METHODS_CALL",
      "PAYMENT_METHOD_CHANGED",
      "SESSIONS_CALL",
      "RETRIEVE_CALL",
      "DISPLAY_THREE_DS_SDK",
      "APPLE_PAY_FLOW",
      "PLAID_SDK"
    ];
    if (Core__Option.isSome(arrayOfLogs.find(function (log) {
                return [
                          "ERROR",
                          "DEBUG"
                        ].includes(log.logType) ? true : (
                          priorityEventNames.includes(log.eventName) ? log.firstEvent : false
                        );
              }))) {
      return true;
    } else {
      return arrayOfLogs.length > 8;
    }
  };
  var checkLogSizeAndSendData = function () {
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    } else {
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    }
    if (checkForPriorityEvents(mainLogFile)) {
      return sendLogs();
    }
    
  };
  var calculateLatencyHook = function (eventName, apiLogTypeOpt) {
    var apiLogType = apiLogTypeOpt !== undefined ? apiLogTypeOpt : "Method";
    var currentTimestamp = Date.now();
    var latency;
    if (eventName === "PAYMENT_ATTEMPT") {
      var appRenderedTimestamp = events.contents["APP_RENDERED"];
      latency = appRenderedTimestamp !== undefined ? currentTimestamp - appRenderedTimestamp : 0;
    } else {
      var logRequestTimestamp = events.contents[eventNameToStrMapper(eventName) + "_INIT"];
      latency = logRequestTimestamp !== undefined ? (
          apiLogType === "Request" ? 0 : currentTimestamp - logRequestTimestamp
        ) : 0;
    }
    if (latency > 0) {
      return latency.toString();
    } else {
      return "";
    }
  };
  var checkAndPushMissedEvents = function (eventName, paymentMethod) {
    if (eventName !== "PAYMENT_ATTEMPT") {
      return ;
    }
    var paymentMethodChangedEventStr = "PAYMENT_METHOD_CHANGED";
    var paymentDataFilledEventStr = "PAYMENT_DATA_FILLED";
    var localTimestamp = Date.now().toString();
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    var paymentMethodChangedEvent = Core__Option.isNone(events.contents[paymentMethodChangedEventStr]);
    var paymentDataFilledEvent = Core__Option.isNone(events.contents[paymentDataFilledEventStr]);
    if (paymentMethodChangedEvent) {
      conditionalLogPush({
            timestamp: localTimestamp,
            logType: "INFO",
            category: "USER_EVENT",
            source: sourceString,
            version: repoVersion,
            value: "",
            internalMetadata: "",
            sessionId: sessionId$1.contents,
            merchantId: merchantId$1.contents,
            paymentId: getPaymentId(clientSecret$1.contents),
            appId: "",
            platform: window.navigator.platform,
            browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
            browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
            userAgent: window.navigator.userAgent,
            eventName: "PAYMENT_METHOD_CHANGED",
            latency: "",
            firstEvent: true,
            paymentMethod: paymentMethod,
            metadata: metadata$1.contents,
            ephemeralKey: ephemeralKey$1.contents
          });
      events.contents[paymentMethodChangedEventStr] = localTimestampFloat;
    }
    if (paymentDataFilledEvent) {
      conditionalLogPush({
            timestamp: localTimestamp,
            logType: "INFO",
            category: "USER_EVENT",
            source: sourceString,
            version: repoVersion,
            value: "",
            internalMetadata: "",
            sessionId: sessionId$1.contents,
            merchantId: merchantId$1.contents,
            paymentId: getPaymentId(clientSecret$1.contents),
            appId: "",
            platform: window.navigator.platform,
            browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
            browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
            userAgent: window.navigator.userAgent,
            eventName: "PAYMENT_DATA_FILLED",
            latency: "",
            firstEvent: true,
            paymentMethod: paymentMethod,
            metadata: metadata$1.contents,
            ephemeralKey: ephemeralKey$1.contents
          });
      events.contents[paymentDataFilledEventStr] = localTimestampFloat;
      return ;
    }
    
  };
  var setLogInfo = function (value, internalMetadataOpt, eventName, timestamp, latency, logTypeOpt, logCategoryOpt, paymentMethodOpt) {
    var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
    var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "USER_EVENT";
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    checkAndPushMissedEvents(eventName, paymentMethod);
    var eventNameStr = eventNameToStrMapper(eventName);
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency$1 = latency !== undefined ? latency.toString() : calculateLatencyHook(eventName, undefined);
    var localTimestamp = Core__Option.getOr(timestamp, Date.now().toString());
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: sourceString,
          version: repoVersion,
          value: value,
          internalMetadata: internalMetadata,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: eventName,
          latency: latency$1,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setConfirmPaymentValue = function (paymentType) {
    return Object.fromEntries([
                [
                  "method",
                  "confirmPayment"
                ],
                [
                  "type",
                  paymentType
                ]
              ]);
  };
  var setLogApi = function (value, internalMetadata, eventName, timestamp, logTypeOpt, logCategoryOpt, paymentMethodOpt, apiLogTypeOpt, isPaymentSessionOpt) {
    var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "API";
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    var apiLogType = apiLogTypeOpt !== undefined ? apiLogTypeOpt : "Request";
    var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
    var eventNameStr = eventNameToStrMapper(eventName);
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency = calculateLatencyHook(eventName, apiLogType);
    var localTimestamp = Core__Option.getOr(timestamp, Date.now().toString());
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    var tmp;
    tmp = value.TAG === "ArrayType" ? JSON.stringify(Object.fromEntries(value._0)) : value._0;
    var tmp$1;
    tmp$1 = internalMetadata.TAG === "ArrayType" ? JSON.stringify(Object.fromEntries(internalMetadata._0)) : internalMetadata._0;
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: isPaymentSession ? "headless" : sourceString,
          version: repoVersion,
          value: tmp,
          internalMetadata: tmp$1,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: eventName,
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setLogError = function (value, internalMetadataOpt, eventName, timestamp, latency, logTypeOpt, logCategoryOpt, paymentMethodOpt) {
    var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
    var logType = logTypeOpt !== undefined ? logTypeOpt : "ERROR";
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "USER_ERROR";
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    var eventNameStr = eventNameToStrMapper(eventName);
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency$1 = latency !== undefined ? latency.toString() : calculateLatencyHook(eventName, undefined);
    var localTimestamp = Core__Option.getOr(timestamp, Date.now().toString());
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: sourceString,
          version: repoVersion,
          value: value,
          internalMetadata: internalMetadata,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: eventName,
          latency: latency$1,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setLogInitiated = function () {
    var eventNameStr = eventNameToStrMapper("LOG_INITIATED");
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency = calculateLatencyHook("LOG_INITIATED", undefined);
    conditionalLogPush({
          timestamp: Date.now().toString(),
          logType: "INFO",
          category: "USER_EVENT",
          source: sourceString,
          version: repoVersion,
          value: "log initiated",
          internalMetadata: "",
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: "LOG_INITIATED",
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: "",
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = Date.now();
  };
  var handleBeforeUnload = function (_event) {
    sendLogs();
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      return ;
    }
    
  };
  window.addEventListener("beforeunload", handleBeforeUnload);
  return {
          setLogInfo: setLogInfo,
          setLogError: setLogError,
          setLogApi: setLogApi,
          setLogInitiated: setLogInitiated,
          setConfirmPaymentValue: setConfirmPaymentValue,
          sendLogs: sendLogs,
          setSessionId: setSessionId,
          setClientSecret: setClientSecret,
          setMerchantId: setMerchantId,
          setMetadata: setMetadata,
          setSource: setSource,
          setEphemeralKey: setEphemeralKey
        };
}

export {
  eventNameToStrMapper ,
  getPaymentId ,
  convertToScreamingSnakeCase ,
  toSnakeCaseWithSeparator ,
  logInfo ,
  defaultLoggerConfig ,
  logFileToObj ,
  getRefFromOption ,
  getSourceString ,
  findVersion ,
  browserDetect ,
  arrayOfNameAndVersion ,
  make ,
}
/* arrayOfNameAndVersion Not a pure module */
