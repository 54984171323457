// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as ConfirmType$OrcaPaymentPage from "../Types/ConfirmType.bs.js";
import * as PaymentBody$OrcaPaymentPage from "./PaymentBody.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./RecoilAtoms.bs.js";
import * as PaymentUtils$OrcaPaymentPage from "./PaymentUtils.bs.js";
import * as UtilityHooks$OrcaPaymentPage from "../Hooks/UtilityHooks.bs.js";
import * as ApplePayTypes$OrcaPaymentPage from "../Types/ApplePayTypes.bs.js";
import * as TaxCalculation$OrcaPaymentPage from "./TaxCalculation.bs.js";
import * as DynamicFieldsUtils$OrcaPaymentPage from "./DynamicFieldsUtils.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function processPayment(bodyArr, isThirdPartyFlowOpt, isGuestCustomer, paymentMethodListValueOpt, intent, options, publishableKey, isManualRetryEnabled) {
  var isThirdPartyFlow = isThirdPartyFlowOpt !== undefined ? isThirdPartyFlowOpt : false;
  var paymentMethodListValue = paymentMethodListValueOpt !== undefined ? paymentMethodListValueOpt : PaymentMethodsRecord$OrcaPaymentPage.defaultList;
  var requestBody = PaymentUtils$OrcaPaymentPage.appendedCustomerAcceptance(isGuestCustomer, paymentMethodListValue.payment_type, bodyArr);
  intent(true, requestBody, {
        return_url: options.wallets.walletReturnUrl,
        publishableKey: publishableKey
      }, undefined, isThirdPartyFlow, undefined, isManualRetryEnabled);
}

function getApplePayFromResponse(token, billingContactDict, shippingContactDict, requiredFieldsOpt, stateJson, connectors, isPaymentSessionOpt, isSavedMethodsFlowOpt) {
  var requiredFields = requiredFieldsOpt !== undefined ? requiredFieldsOpt : [];
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var isSavedMethodsFlow = isSavedMethodsFlowOpt !== undefined ? isSavedMethodsFlowOpt : false;
  var billingContact = ApplePayTypes$OrcaPaymentPage.billingContactItemToObjMapper(billingContactDict);
  var shippingContact = ApplePayTypes$OrcaPaymentPage.shippingContactItemToObjMapper(shippingContactDict);
  var requiredFieldsBody = isPaymentSession || isSavedMethodsFlow ? DynamicFieldsUtils$OrcaPaymentPage.getApplePayRequiredFields(billingContact, shippingContact, undefined, stateJson) : DynamicFieldsUtils$OrcaPaymentPage.getApplePayRequiredFields(billingContact, shippingContact, requiredFields, stateJson);
  var bodyDict = PaymentBody$OrcaPaymentPage.applePayBody(token, connectors);
  return Utils$OrcaPaymentPage.getArrayOfTupleFromDict(Utils$OrcaPaymentPage.mergeTwoFlattenedJsonDicts(Utils$OrcaPaymentPage.flattenObject(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(bodyDict), true), requiredFieldsBody));
}

function startApplePaySession(paymentRequest, applePaySessionRef, applePayPresent, logger, applePayEventOpt, callBackFunc, resolvePromiseOpt, clientSecret, publishableKey, isTaxCalculationEnabledOpt) {
  var applePayEvent = applePayEventOpt !== undefined ? Caml_option.valFromOption(applePayEventOpt) : undefined;
  var resolvePromise = resolvePromiseOpt !== undefined ? Caml_option.valFromOption(resolvePromiseOpt) : undefined;
  var isTaxCalculationEnabled = isTaxCalculationEnabledOpt !== undefined ? isTaxCalculationEnabledOpt : false;
  var ssn = new ApplePaySession(3, paymentRequest);
  var session = applePaySessionRef.contents;
  if (!(session == null)) {
    try {
      session.abort();
    }
    catch (raw_error){
      var error = Caml_js_exceptions.internalToOCamlException(raw_error);
      console.log("Abort fail", error);
    }
  }
  applePaySessionRef.contents = ssn;
  ssn.onvalidatemerchant = (function (_event) {
      var merchantSession = Utils$OrcaPaymentPage.transformKeys(Core__Option.getOr(Core__Option.getOr(Belt_Option.flatMap(applePayPresent, Core__JSON.Decode.object), {})["session_token_data"], {}), "CamelCase");
      ssn.completeMerchantValidation(merchantSession);
    });
  ssn.onshippingcontactselected = (function (shippingAddressChangeEvent) {
      if (!isTaxCalculationEnabled) {
        return ;
      }
      var newShippingContact = ApplePayTypes$OrcaPaymentPage.shippingContactItemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(shippingAddressChangeEvent.shippingContact));
      var newShippingAddress = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
            [
              "state",
              newShippingContact.locality
            ],
            [
              "country",
              newShippingContact.countryCode
            ],
            [
              "zip",
              newShippingContact.postalCode
            ]
          ]);
      TaxCalculation$OrcaPaymentPage.calculateTax(Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "address",
                    newShippingAddress
                  ]]), logger, clientSecret, publishableKey, "apple_pay").then(function (response) {
            var taxCalculationResponse = TaxCalculation$OrcaPaymentPage.taxResponseToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(response));
            var netAmount = taxCalculationResponse.net_amount;
            var ordertaxAmount = taxCalculationResponse.order_tax_amount;
            var shippingCost = taxCalculationResponse.shipping_cost;
            var newTotal_amount = netAmount.toString();
            var newTotal = {
              label: "Net Amount",
              amount: newTotal_amount,
              type: "final"
            };
            var newLineItems = [
              {
                label: "Bag Subtotal",
                amount: ((netAmount - ordertaxAmount | 0) - shippingCost | 0).toString(),
                type: "final"
              },
              {
                label: "Order Tax Amount",
                amount: ordertaxAmount.toString(),
                type: "final"
              },
              {
                label: "Shipping Cost",
                amount: shippingCost.toString(),
                type: "final"
              }
            ];
            var updatedOrderDetails = {
              newTotal: newTotal,
              newLineItems: newLineItems
            };
            return Promise.resolve(ssn.completeShippingContactSelection(updatedOrderDetails));
          });
    });
  ssn.onpaymentauthorized = (function ($$event) {
      ssn.completePayment({
            status: ssn.STATUS_SUCCESS
          });
      applePaySessionRef.contents = null;
      logger.setLogInfo("Payment Data Filled: New Payment Method", undefined, "PAYMENT_DATA_FILLED", undefined, undefined, undefined, undefined, "APPLE_PAY");
      var payment = $$event.payment;
      callBackFunc(payment);
    });
  ssn.oncancel = (function (_ev) {
      applePaySessionRef.contents = null;
      Utils$OrcaPaymentPage.logInfo((console.log("Apple Pay Payment Cancelled"), undefined));
      logger.setLogInfo("Apple Pay Payment Cancelled", undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
      if (applePayEvent === undefined) {
        if (resolvePromise !== undefined) {
          return resolvePromise(Utils$OrcaPaymentPage.handleFailureResponse("ApplePay Session Cancelled", "apple_pay"));
        } else {
          return ;
        }
      }
      var msg = Object.fromEntries([[
              "showApplePayButton",
              true
            ]]);
      Window$OrcaPaymentPage.sendPostMessage(applePayEvent.source, msg);
    });
  ssn.begin();
}

function useHandleApplePayResponse(connectors, intent, setApplePayClickedOpt, syncPaymentOpt, isInvokeSDKFlowOpt, isSavedMethodsFlowOpt, isWalletOpt, requiredFieldsBodyOpt) {
  var setApplePayClicked = setApplePayClickedOpt !== undefined ? setApplePayClickedOpt : (function (param) {
        
      });
  var syncPayment = syncPaymentOpt !== undefined ? syncPaymentOpt : (function () {
        
      });
  var isInvokeSDKFlow = isInvokeSDKFlowOpt !== undefined ? isInvokeSDKFlowOpt : true;
  var isSavedMethodsFlow = isSavedMethodsFlowOpt !== undefined ? isSavedMethodsFlowOpt : false;
  var isWallet = isWalletOpt !== undefined ? isWalletOpt : true;
  var requiredFieldsBody = requiredFieldsBodyOpt !== undefined ? requiredFieldsBodyOpt : ({});
  var options = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var publishableKey = match.publishableKey;
  var paymentMethodListValue = Recoil.useRecoilValue(PaymentUtils$OrcaPaymentPage.paymentMethodListValue);
  var match$1 = React.useState(function () {
        return null;
      });
  var stateJson = match$1[0];
  var isGuestCustomer = UtilityHooks$OrcaPaymentPage.useIsGuestCustomer();
  PaymentUtils$OrcaPaymentPage.useStatesJson(match$1[1]);
  var paymentMethodTypes = DynamicFieldsUtils$OrcaPaymentPage.usePaymentMethodTypeFromList(paymentMethodListValue, "wallet", "apple_pay");
  var isManualRetryEnabled = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  React.useEffect((function () {
          var handleApplePayMessages = function (ev) {
            var json = Utils$OrcaPaymentPage.safeParse(ev.data);
            try {
              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
              if (!Core__Option.isSome(dict["applePayProcessPayment"])) {
                if (Core__Option.isSome(dict["showApplePayButton"])) {
                  setApplePayClicked(function (param) {
                        return false;
                      });
                  if (isSavedMethodsFlow || !isWallet) {
                    return Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
                  } else {
                    return ;
                  }
                } else if (Core__Option.isSome(dict["applePaySyncPayment"])) {
                  return syncPayment();
                } else {
                  return ;
                }
              }
              var token = Core__Option.getOr(dict["applePayProcessPayment"], {});
              var billingContactDict = Utils$OrcaPaymentPage.getDictFromDict(dict, "applePayBillingContact");
              var shippingContactDict = Utils$OrcaPaymentPage.getDictFromDict(dict, "applePayShippingContact");
              var applePayBody = getApplePayFromResponse(token, billingContactDict, shippingContactDict, paymentMethodTypes.required_fields, stateJson, connectors, undefined, isSavedMethodsFlow);
              var bodyArr = isWallet ? applePayBody : Utils$OrcaPaymentPage.getArrayOfTupleFromDict(Utils$OrcaPaymentPage.mergeTwoFlattenedJsonDicts(Utils$OrcaPaymentPage.flattenObject(Utils$OrcaPaymentPage.getJsonFromArrayOfJson(applePayBody), true), requiredFieldsBody));
              return processPayment(bodyArr, false, isGuestCustomer, paymentMethodListValue, intent, options, publishableKey, isManualRetryEnabled);
            }
            catch (exn){
              return Utils$OrcaPaymentPage.logInfo((console.log("Error in parsing Apple Pay Data"), undefined));
            }
          };
          window.addEventListener("message", handleApplePayMessages);
          return (function () {
                    Utils$OrcaPaymentPage.messageParentWindow(undefined, [[
                            "applePaySessionAbort",
                            true
                          ]]);
                    window.removeEventListener("message", handleApplePayMessages);
                  });
        }), [
        isInvokeSDKFlow,
        processPayment,
        stateJson,
        isManualRetryEnabled,
        isWallet,
        requiredFieldsBody
      ]);
}

function handleApplePayButtonClicked(sessionObj, componentName, paymentMethodListValue) {
  var paymentRequest = ApplePayTypes$OrcaPaymentPage.getPaymentRequestFromSession(sessionObj, componentName);
  var message = [
    [
      "applePayButtonClicked",
      true
    ],
    [
      "applePayPaymentRequest",
      paymentRequest
    ],
    [
      "isTaxCalculationEnabled",
      paymentMethodListValue.is_tax_calculation_enabled
    ]
  ];
  Utils$OrcaPaymentPage.messageParentWindow(undefined, message);
}

function useSubmitCallback(isWallet, sessionObj, componentName) {
  var areRequiredFieldsValid = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.areRequiredFieldsValid);
  var areRequiredFieldsEmpty = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.areRequiredFieldsEmpty);
  var options = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var localeString = match.localeString;
  var paymentMethodListValue = Recoil.useRecoilValue(PaymentUtils$OrcaPaymentPage.paymentMethodListValue);
  return React.useCallback((function (ev) {
                if (isWallet) {
                  return ;
                }
                var json = Utils$OrcaPaymentPage.safeParse(ev.data);
                var confirm = ConfirmType$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json));
                if (confirm.doSubmit && areRequiredFieldsValid && !areRequiredFieldsEmpty) {
                  if (!options.readOnly) {
                    return handleApplePayButtonClicked(sessionObj, componentName, paymentMethodListValue);
                  } else {
                    return ;
                  }
                } else if (areRequiredFieldsEmpty) {
                  return Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterFieldsText);
                } else if (!areRequiredFieldsValid) {
                  return Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterValidDetailsText);
                } else {
                  return ;
                }
              }), [
              areRequiredFieldsValid,
              areRequiredFieldsEmpty,
              isWallet,
              sessionObj,
              componentName
            ]);
}

export {
  processPayment ,
  getApplePayFromResponse ,
  startApplePaySession ,
  useHandleApplePayResponse ,
  handleApplePayButtonClicked ,
  useSubmitCallback ,
}
/* react Not a pure module */
