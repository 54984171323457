// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Recoil$OrcaPaymentPage from "../libraries/Recoil.bs.js";
import * as CardTheme$OrcaPaymentPage from "../CardTheme.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";
import * as CommonHooks$OrcaPaymentPage from "../Hooks/CommonHooks.bs.js";
import * as ElementType$OrcaPaymentPage from "../Types/ElementType.bs.js";
import * as PaymentType$OrcaPaymentPage from "../Types/PaymentType.bs.js";
import * as PortalState$OrcaPaymentPage from "../Hooks/PortalState.bs.js";
import * as PaymentMethodCollectUtils$OrcaPaymentPage from "./PaymentMethodCollectUtils.bs.js";

var keys = Recoil$OrcaPaymentPage.atom("keys", CommonHooks$OrcaPaymentPage.defaultkeys);

var configAtom = Recoil$OrcaPaymentPage.atom("defaultRecoilConfig", CardTheme$OrcaPaymentPage.defaultRecoilConfig);

var portalNodes = Recoil$OrcaPaymentPage.atom("portalNodes", PortalState$OrcaPaymentPage.defaultDict);

var elementOptions = Recoil$OrcaPaymentPage.atom("elementOptions", ElementType$OrcaPaymentPage.defaultOptions);

var optionAtom = Recoil$OrcaPaymentPage.atom("options", PaymentType$OrcaPaymentPage.defaultOptions);

var sessions = Recoil$OrcaPaymentPage.atom("sessions", "Loading");

var paymentMethodList = Recoil$OrcaPaymentPage.atom("paymentMethodList", "Loading");

var loggerAtom = Recoil$OrcaPaymentPage.atom("component", OrcaLogger$OrcaPaymentPage.defaultLoggerConfig);

var sessionId = Recoil$OrcaPaymentPage.atom("sessionId", "");

var isConfirmBlocked = Recoil$OrcaPaymentPage.atom("isConfirmBlocked", false);

var customPodUri = Recoil$OrcaPaymentPage.atom("customPodUri", "");

var selectedOptionAtom = Recoil$OrcaPaymentPage.atom("selectedOption", "");

var paymentTokenAtom = Recoil$OrcaPaymentPage.atom("paymentToken", {
      paymentToken: "",
      customerId: ""
    });

var showCardFieldsAtom = Recoil$OrcaPaymentPage.atom("showCardFields", false);

var phoneJson = Recoil$OrcaPaymentPage.atom("phoneJson", "Loading");

var cardBrand = Recoil$OrcaPaymentPage.atom("cardBrand", "");

var paymentMethodCollectOptionAtom = Recoil$OrcaPaymentPage.atom("paymentMethodCollectOptions", PaymentMethodCollectUtils$OrcaPaymentPage.defaultPaymentMethodCollectOptions);

var payoutDynamicFieldsAtom = Recoil$OrcaPaymentPage.atom("payoutDynamicFields", PaymentMethodCollectUtils$OrcaPaymentPage.defaultPayoutDynamicFields(undefined));

var paymentMethodTypeAtom = Recoil$OrcaPaymentPage.atom("paymentMethodType", PaymentMethodCollectUtils$OrcaPaymentPage.defaultPmt(undefined));

var formDataAtom = Recoil$OrcaPaymentPage.atom("formData", PaymentMethodCollectUtils$OrcaPaymentPage.defaultFormDataDict);

var validityDictAtom = Recoil$OrcaPaymentPage.atom("validityDict", PaymentMethodCollectUtils$OrcaPaymentPage.defaultValidityDict);

var defaultFieldValues = {
  value: "",
  errorString: ""
};

var userFullName = Recoil$OrcaPaymentPage.atom("userFullName", defaultFieldValues);

var userEmailAddress = Recoil$OrcaPaymentPage.atom("userEmailAddress", defaultFieldValues);

var newrecord = Caml_obj.obj_dup(defaultFieldValues);

var userPhoneNumber = Recoil$OrcaPaymentPage.atom("userPhoneNumber", (newrecord.countryCode = "", newrecord));

var isGooglePayReady = Recoil$OrcaPaymentPage.atom("isGooglePayReady", false);

var isApplePayReady = Recoil$OrcaPaymentPage.atom("isApplePayReady", false);

var userCountry = Recoil$OrcaPaymentPage.atom("userCountry", "");

var userBank = Recoil$OrcaPaymentPage.atom("userBank", "");

var userAddressline1 = Recoil$OrcaPaymentPage.atom("userAddressline1", defaultFieldValues);

var userAddressline2 = Recoil$OrcaPaymentPage.atom("userAddressline2", defaultFieldValues);

var userAddressCity = Recoil$OrcaPaymentPage.atom("userAddressCity", defaultFieldValues);

var userAddressPincode = Recoil$OrcaPaymentPage.atom("userAddressPincode", defaultFieldValues);

var userAddressState = Recoil$OrcaPaymentPage.atom("userAddressState", defaultFieldValues);

var userAddressCountry = Recoil$OrcaPaymentPage.atom("userAddressCountry", defaultFieldValues);

var userBlikCode = Recoil$OrcaPaymentPage.atom("userBlikCode", defaultFieldValues);

var fieldsComplete = Recoil$OrcaPaymentPage.atom("fieldsComplete", false);

var isManualRetryEnabled = Recoil$OrcaPaymentPage.atom("isManualRetryEnabled", false);

var userCurrency = Recoil$OrcaPaymentPage.atom("userCurrency", "");

var cryptoCurrencyNetworks = Recoil$OrcaPaymentPage.atom("cryptoCurrencyNetworks", "");

var isShowOrPayUsing = Recoil$OrcaPaymentPage.atom("isShowOrPayUsing", false);

var areRequiredFieldsValid = Recoil$OrcaPaymentPage.atom("areRequiredFieldsValid", true);

var areRequiredFieldsEmpty = Recoil$OrcaPaymentPage.atom("areRequiredFieldsEmpty", false);

var dateOfBirth = Recoil$OrcaPaymentPage.atom("dateOfBirth", null);

var userBillingName = Recoil$OrcaPaymentPage.atom("userBillingName", defaultFieldValues);

var userVpaId = Recoil$OrcaPaymentPage.atom("userVpaId", defaultFieldValues);

var userPixKey = Recoil$OrcaPaymentPage.atom("userPixKey", defaultFieldValues);

var userPixCPF = Recoil$OrcaPaymentPage.atom("userPixCPF", defaultFieldValues);

var userPixCNPJ = Recoil$OrcaPaymentPage.atom("userPixCNPJ", defaultFieldValues);

var defaultAreOneClickWalletsRendered = {
  isGooglePay: false,
  isApplePay: false,
  isPaypal: false,
  isKlarna: false
};

var areOneClickWalletsRendered = Recoil$OrcaPaymentPage.atom("areOneClickWalletsBtnRendered", defaultAreOneClickWalletsRendered);

export {
  keys ,
  configAtom ,
  portalNodes ,
  elementOptions ,
  optionAtom ,
  sessions ,
  paymentMethodList ,
  loggerAtom ,
  sessionId ,
  isConfirmBlocked ,
  customPodUri ,
  selectedOptionAtom ,
  paymentTokenAtom ,
  showCardFieldsAtom ,
  phoneJson ,
  cardBrand ,
  paymentMethodCollectOptionAtom ,
  payoutDynamicFieldsAtom ,
  paymentMethodTypeAtom ,
  formDataAtom ,
  validityDictAtom ,
  defaultFieldValues ,
  userFullName ,
  userEmailAddress ,
  userPhoneNumber ,
  isGooglePayReady ,
  isApplePayReady ,
  userCountry ,
  userBank ,
  userAddressline1 ,
  userAddressline2 ,
  userAddressCity ,
  userAddressPincode ,
  userAddressState ,
  userAddressCountry ,
  userBlikCode ,
  fieldsComplete ,
  isManualRetryEnabled ,
  userCurrency ,
  cryptoCurrencyNetworks ,
  isShowOrPayUsing ,
  areRequiredFieldsValid ,
  areRequiredFieldsEmpty ,
  dateOfBirth ,
  userBillingName ,
  userVpaId ,
  userPixKey ,
  userPixCPF ,
  userPixCNPJ ,
  defaultAreOneClickWalletsRendered ,
  areOneClickWalletsRendered ,
}
/* keys Not a pure module */
