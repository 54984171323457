// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Types$OrcaPaymentPage from "./Types.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Sentry$OrcaPaymentPage from "../orca-log-catcher/Sentry.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "../Utilities/ApiEndpoint.bs.js";
import * as PaymentType$OrcaPaymentPage from "../Types/PaymentType.bs.js";
import * as SessionsType$OrcaPaymentPage from "../Types/SessionsType.bs.js";
import * as ApplePayTypes$OrcaPaymentPage from "../Types/ApplePayTypes.bs.js";
import * as GooglePayType$OrcaPaymentPage from "../Types/GooglePayType.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "../Utilities/PaymentHelpers.bs.js";
import * as ApplePayHelpers$OrcaPaymentPage from "../Utilities/ApplePayHelpers.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";
import * as LoaderPaymentElement$OrcaPaymentPage from "./LoaderPaymentElement.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function make(options, setIframeRef, clientSecret, sdkSessionId, publishableKey, logger, analyticsMetadata, customBackendUrl) {
  var applePaySessionRef = {
    contents: null
  };
  try {
    var iframeRef = [];
    var logger$1 = Core__Option.getOr(logger, OrcaLogger$OrcaPaymentPage.defaultLoggerConfig);
    var savedPaymentElement = {};
    var localOptions = Core__Option.getOr(Core__JSON.Decode.object(options), {});
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(publishableKey, undefined);
    var redirect = {
      contents: "if_required"
    };
    var appearance = Core__Option.getOr(localOptions["appearance"], {});
    var launchTime = Utils$OrcaPaymentPage.getFloat(localOptions, "launchTime", 0.0);
    var fonts = Core__Option.getOr(Core__Option.flatMap(localOptions["fonts"], Core__JSON.Decode.array), []);
    var blockConfirm = GlobalVars$OrcaPaymentPage.isInteg && Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(options), (function (x) {
                    return x["blockConfirm"];
                  })), Core__JSON.Decode.bool), false);
    var customPodUri = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(options), (function (x) {
                    return x["customPodUri"];
                  })), Core__JSON.Decode.string), "");
    var merchantHostname = window.location.hostname;
    var localSelectorString = "hyper-preMountLoader-iframe";
    var mountPreMountLoaderIframe = function () {
      if (document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString) == null) {
        var componentType = "preMountLoader";
        var iframeDivHtml = "<div id=\"orca-element-" + localSelectorString + "\" style= \"height: 0px; width: 0px; display: none;\"  class=\"" + componentType + "\">\n          <div id=\"orca-fullscreen-iframeRef-" + localSelectorString + "\"></div>\n            <iframe\n              id=\"orca-payment-element-iframeRef-" + localSelectorString + "\"\n              name=\"orca-payment-element-iframeRef-" + localSelectorString + "\"\n              src=\"" + ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/index.html?fullscreenType=" + componentType + "&publishableKey=" + publishableKey + "&clientSecret=" + clientSecret + "&sessionId=" + sdkSessionId + "&endpoint=" + endpoint + "&merchantHostname=" + merchantHostname + "&customPodUri=" + customPodUri + "\"              allow=\"*\"\n              name=\"orca-payment\"\n            ></iframe>\n          </div>";
        var iframeDiv = document.createElement("div");
        iframeDiv.innerHTML = iframeDivHtml;
        document.body.appendChild(iframeDiv);
      }
      return document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString);
    };
    var locale = Utils$OrcaPaymentPage.getJsonStringFromDict(localOptions, "locale", "");
    var loader = Utils$OrcaPaymentPage.getJsonStringFromDict(localOptions, "loader", "");
    var clientSecret$1 = Utils$OrcaPaymentPage.getRequiredString(localOptions, "clientSecret", "", logger$1);
    var clientSecretReMatch = new RegExp(".+_secret_[A-Za-z0-9]+").test(clientSecret$1);
    var preMountLoaderIframeDiv = mountPreMountLoaderIframe();
    var unMountPreMountLoaderIframe = function () {
      if (!(preMountLoaderIframeDiv == null)) {
        preMountLoaderIframeDiv.remove();
        return ;
      }
      
    };
    var preMountLoaderMountedPromise = new Promise((function (resolve, _reject) {
            var preMountLoaderIframeCallback = function (ev) {
              var json = ev.data;
              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
              if (Core__Option.isSome(dict["preMountLoaderIframeMountedCallback"])) {
                return resolve(true);
              } else if (Core__Option.isSome(dict["preMountLoaderIframeUnMount"])) {
                return unMountPreMountLoaderIframe();
              } else {
                return ;
              }
            };
            EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", preMountLoaderIframeCallback, "onPreMountLoaderIframeCallback");
          }));
    var onPlaidCallback = function (mountedIframeRef) {
      return function (ev) {
        var json = ev.data;
        var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
        var isPlaidExist = Utils$OrcaPaymentPage.getBool(dict, "isPlaid", false);
        if (isPlaidExist) {
          return Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, Object.fromEntries([
                          [
                            "isPlaid",
                            true
                          ],
                          [
                            "data",
                            json
                          ]
                        ]));
        }
        
      };
    };
    var fetchPaymentsList = function (mountedIframeRef, componentType) {
      var handlePaymentMethodsLoaded = function ($$event) {
        var json = $$event.data;
        var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
        var isPaymentMethodsData = Utils$OrcaPaymentPage.getString(dict, "data", "") === "payment_methods";
        if (!isPaymentMethodsData) {
          return ;
        }
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", onPlaidCallback(mountedIframeRef), "onPlaidCallback");
        var json$1 = Utils$OrcaPaymentPage.getJsonFromDict(dict, "response", null);
        var isApplePayPresent = Core__Option.isSome(PaymentMethodsRecord$OrcaPaymentPage.getPaymentMethodTypeFromList(PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json$1)), "wallet", "apple_pay"));
        var isGooglePayPresent = Core__Option.isSome(PaymentMethodsRecord$OrcaPaymentPage.getPaymentMethodTypeFromList(PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json$1)), "wallet", "google_pay"));
        if ((isApplePayPresent || isGooglePayPresent) && document.querySelectorAll("script[src=\"https://tpgw.trustpay.eu/js/v1.js\"]").length === 0 && document.querySelectorAll("script[src=\"https://test-tpgw.trustpay.eu/js/v1.js\"]").length === 0) {
          var trustPayScriptURL = publishableKey.startsWith("pk_prd_") ? "https://tpgw.trustpay.eu/js/v1.js" : "https://test-tpgw.trustpay.eu/js/v1.js";
          var trustPayScript = document.createElement("script");
          logger$1.setLogInfo("TrustPay Script Loading", undefined, "TRUSTPAY_SCRIPT", undefined, undefined, undefined, undefined, undefined);
          trustPayScript.src = trustPayScriptURL;
          trustPayScript.onerror = (function (err) {
              Utils$OrcaPaymentPage.logInfo((console.log("ERROR DURING LOADING TRUSTPAY APPLE PAY", err), undefined));
            });
          trustPayScript.onload = (function () {
              logger$1.setLogInfo("TrustPay Script Loaded", undefined, "TRUSTPAY_SCRIPT", undefined, undefined, undefined, undefined, undefined);
            });
          document.body.appendChild(trustPayScript);
        }
        var msg = Object.fromEntries([[
                "paymentMethodList",
                json$1
              ]]);
        Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
      };
      var msg = Object.fromEntries([[
              "sendPaymentMethodsResponse",
              true
            ]]);
      EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handlePaymentMethodsLoaded, "onPaymentMethodsLoaded-" + componentType);
      Window$OrcaPaymentPage.iframePostMessage(preMountLoaderIframeDiv, msg);
    };
    var fetchCustomerPaymentMethods = function (mountedIframeRef, disableSavedPaymentMethods, componentType) {
      if (!disableSavedPaymentMethods) {
        var handleCustomerPaymentMethodsLoaded = function ($$event) {
          var json = $$event.data;
          var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
          var isCustomerPaymentMethodsData = Utils$OrcaPaymentPage.getString(dict, "data", "") === "customer_payment_methods";
          if (!isCustomerPaymentMethodsData) {
            return ;
          }
          var json$1 = Utils$OrcaPaymentPage.getJsonFromDict(dict, "response", null);
          var msg = Object.fromEntries([[
                  "customerPaymentMethods",
                  json$1
                ]]);
          Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
        };
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleCustomerPaymentMethodsLoaded, "onCustomerPaymentMethodsLoaded-" + componentType);
      }
      var msg = Object.fromEntries([[
              "sendCustomerPaymentMethodsResponse",
              !disableSavedPaymentMethods
            ]]);
      Window$OrcaPaymentPage.iframePostMessage(preMountLoaderIframeDiv, msg);
    };
    if (!clientSecretReMatch) {
      ErrorUtils$OrcaPaymentPage.manageErrorWarning("INVALID_FORMAT", "clientSecret is expected to be in format ******_secret_*****", logger$1);
    }
    var setElementIframeRef = function (ref) {
      iframeRef.push(ref);
      setIframeRef(ref);
    };
    var getElement = function (componentName) {
      return savedPaymentElement[componentName];
    };
    var update = function (newOptions) {
      var newOptionsDict = Utils$OrcaPaymentPage.getDictFromJson(newOptions);
      var val = newOptionsDict["locale"];
      if (val !== undefined) {
        localOptions["locale"] = val;
      }
      var val$1 = newOptionsDict["appearance"];
      if (val$1 !== undefined) {
        localOptions["appearance"] = val$1;
      }
      var val$2 = newOptionsDict["clientSecret"];
      if (val$2 !== undefined) {
        localOptions["clientSecret"] = val$2;
      }
      iframeRef.forEach(function (iframe) {
            var message = Object.fromEntries([
                  [
                    "ElementsUpdate",
                    true
                  ],
                  [
                    "options",
                    newOptionsDict
                  ]
                ]);
            Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
    };
    var fetchUpdates = function () {
      return new Promise((function (resolve, param) {
                    setTimeout((function () {
                            resolve({});
                          }), 1000);
                  }));
    };
    var create = function (componentType, newOptions) {
      if (componentType === "") {
        ErrorUtils$OrcaPaymentPage.manageErrorWarning("REQUIRED_PARAMETER", "type", logger$1);
      }
      var otherElements = Utils$OrcaPaymentPage.isOtherElements(componentType);
      switch (componentType) {
        case "applePay" :
        case "card" :
        case "cardCvc" :
        case "cardExpiry" :
        case "cardNumber" :
        case "expressCheckout" :
        case "googlePay" :
        case "klarna" :
        case "payPal" :
        case "payment" :
        case "paymentMethodCollect" :
        case "paymentMethodsManagement" :
            break;
        default:
          ErrorUtils$OrcaPaymentPage.manageErrorWarning("UNKNOWN_KEY", componentType + " type in create", logger$1);
      }
      var mountPostMessage = function (mountedIframeRef, selectorString, sdkHandleOneClickConfirmPayment) {
        var widgetOptions = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
              [
                "clientSecret",
                clientSecret$1
              ],
              [
                "appearance",
                appearance
              ],
              [
                "locale",
                locale
              ],
              [
                "loader",
                loader
              ],
              [
                "fonts",
                fonts
              ]
            ]);
        var message = Object.fromEntries([
              [
                "paymentElementCreate",
                Utils$OrcaPaymentPage.getIsComponentTypeForPaymentElementCreate(componentType)
              ],
              [
                "otherElements",
                otherElements
              ],
              [
                "options",
                newOptions
              ],
              [
                "componentType",
                componentType
              ],
              [
                "paymentOptions",
                widgetOptions
              ],
              [
                "iframeId",
                selectorString
              ],
              [
                "publishableKey",
                publishableKey
              ],
              [
                "endpoint",
                endpoint
              ],
              [
                "sdkSessionId",
                sdkSessionId
              ],
              [
                "blockConfirm",
                blockConfirm
              ],
              [
                "customPodUri",
                customPodUri
              ],
              [
                "sdkHandleOneClickConfirmPayment",
                sdkHandleOneClickConfirmPayment
              ],
              [
                "parentURL",
                "*"
              ],
              [
                "analyticsMetadata",
                analyticsMetadata
              ],
              [
                "launchTime",
                launchTime
              ],
              [
                "customBackendUrl",
                customBackendUrl
              ]
            ]);
        var wallets = PaymentType$OrcaPaymentPage.getWallets(Utils$OrcaPaymentPage.getDictFromJson(newOptions), "wallets", logger$1);
        var handleApplePayMounted = function ($$event) {
          var json = $$event.data;
          var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
          if (!Core__Option.isSome(dict["applePayMounted"])) {
            return ;
          }
          if (wallets.applePay !== "Auto") {
            return logger$1.setLogInfo("ApplePay is set as 'never' by merchant", undefined, "APPLE_PAY_FLOW", undefined, undefined, "INFO", undefined, "APPLE_PAY");
          }
          var session = window.ApplePaySession;
          if (session == null) {
            return ;
          }
          try {
            if (session.canMakePayments()) {
              var msg = Object.fromEntries([[
                      "applePayCanMakePayments",
                      true
                    ]]);
              return Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
            }
            console.log("CANNOT MAKE PAYMENT USING APPLE PAY");
            return logger$1.setLogInfo("CANNOT MAKE PAYMENT USING APPLE PAY", undefined, "APPLE_PAY_FLOW", undefined, undefined, "ERROR", undefined, "APPLE_PAY");
          }
          catch (raw_exn){
            var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
            var exnString = JSON.stringify(exn);
            console.log("CANNOT MAKE PAYMENT USING APPLE PAY: " + exnString);
            return logger$1.setLogInfo(exnString, undefined, "APPLE_PAY_FLOW", undefined, undefined, "ERROR", undefined, "APPLE_PAY");
          }
        };
        var handleGooglePayThirdPartyFlow = function ($$event) {
          var json = $$event.data;
          var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
          var googlePayThirdPartyOptSession = dict["googlePayThirdPartyFlow"];
          if (googlePayThirdPartyOptSession === undefined) {
            return ;
          }
          var googlePayThirdPartySession = Utils$OrcaPaymentPage.getDictFromJson(googlePayThirdPartyOptSession);
          var baseDetails = {
            apiVersion: 2,
            apiVersionMinor: 0,
            environment: publishableKey.startsWith("pk_prd_") ? "PRODUCTION" : "TEST"
          };
          var paymentDataRequest = Object.assign({}, baseDetails);
          var googlePayRequest = GooglePayType$OrcaPaymentPage.jsonToPaymentRequestDataType(paymentDataRequest, googlePayThirdPartySession);
          var headers = [
            [
              "Content-Type",
              "application/json"
            ],
            [
              "api-key",
              publishableKey
            ]
          ];
          var connector = Core__Option.getOr(Core__JSON.Decode.string(Core__Option.getOr(googlePayThirdPartySession["connector"], null)), "");
          try {
            if (connector !== "trustpay") {
              return ;
            }
            var secrets = Utils$OrcaPaymentPage.getJsonFromDict(googlePayThirdPartySession, "secrets", null);
            var payment = Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(secrets), "payment", "");
            var trustpay = new TrustPayApi(secrets);
            var polling = Utils$OrcaPaymentPage.delay(2000).then(function (param) {
                  return PaymentHelpers$OrcaPaymentPage.pollRetrievePaymentIntent(clientSecret$1, headers, logger$1, customPodUri, true);
                });
            var executeGooglePayment = trustpay.executeGooglePayment(payment, googlePayRequest);
            var timeOut = Utils$OrcaPaymentPage.delay(600000).then(function (param) {
                  var errorMsg = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                          "error",
                          "Request Timed Out"
                        ]]);
                  return Promise.reject(Caml_js_exceptions.internalToOCamlException(errorMsg));
                });
            Core__Promise.$$catch(Promise.race([
                        polling,
                        executeGooglePayment,
                        timeOut
                      ]).then(function (res) {
                      logger$1.setLogInfo("TrustPay GooglePay Response", JSON.stringify(res), "GOOGLE_PAY_FLOW", undefined, undefined, undefined, undefined, "GOOGLE_PAY");
                      logger$1.setLogInfo("Payment Data Filled: New Payment Method", undefined, "PAYMENT_DATA_FILLED", undefined, undefined, undefined, undefined, "GOOGLE_PAY");
                      var msg = Object.fromEntries([[
                              "googlePaySyncPayment",
                              true
                            ]]);
                      Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
                      return Promise.resolve();
                    }), (function (err) {
                    var exceptionMessage = JSON.stringify(Utils$OrcaPaymentPage.formatException(err));
                    logger$1.setLogInfo(exceptionMessage, undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "ERROR", "USER_ERROR", "GOOGLE_PAY");
                    var msg = Object.fromEntries([[
                            "googlePaySyncPayment",
                            true
                          ]]);
                    Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
                    return Promise.resolve();
                  }));
            return ;
          }
          catch (raw_err){
            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
            var exceptionMessage = JSON.stringify(Utils$OrcaPaymentPage.formatException(err));
            logger$1.setLogInfo(exceptionMessage, undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "ERROR", "USER_ERROR", "GOOGLE_PAY");
            var msg = Object.fromEntries([[
                    "googlePaySyncPayment",
                    true
                  ]]);
            return Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
          }
        };
        var handleApplePayThirdPartyFlow = function ($$event) {
          var json = $$event.data;
          var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
          var val = dict["applePayButtonClicked"];
          if (val === undefined) {
            return ;
          }
          if (!Core__Option.getOr(Core__JSON.Decode.bool(val), false)) {
            return ;
          }
          var applePaySessionTokenData = Core__Option.getOr(Belt_Option.flatMap(dict["applePayPresent"], Core__JSON.Decode.object), {});
          var isDelayedSessionToken = Core__Option.getOr(Core__JSON.Decode.bool(Core__Option.getOr(applePaySessionTokenData["delayed_session_token"], null)), false);
          if (!isDelayedSessionToken) {
            return ;
          }
          logger$1.setLogInfo("Delayed Session Token Flow", undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
          var connector = Core__Option.getOr(Core__JSON.Decode.string(Core__Option.getOr(applePaySessionTokenData["connector"], null)), "");
          if (connector !== "trustpay") {
            return ;
          }
          logger$1.setLogInfo("TrustPay Connector Flow", undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
          var secrets = Core__Option.getOr(Core__Option.getOr(Core__JSON.Decode.object(Core__Option.getOr(applePaySessionTokenData["session_token_data"], null)), {})["secrets"], null);
          var paymentRequest = ApplePayTypes$OrcaPaymentPage.jsonToPaymentRequestDataType(Core__Option.getOr(Belt_Option.flatMap(applePaySessionTokenData["payment_request_data"], Core__JSON.Decode.object), {}));
          var payment = Core__Option.getOr(Core__JSON.Decode.string(Core__Option.getOr(Core__Option.getOr(Core__JSON.Decode.object(secrets), {})["payment"], null)), "");
          try {
            var trustpay = new TrustPayApi(secrets);
            Core__Promise.$$catch(trustpay.finishApplePaymentV2(payment, paymentRequest).then(function (res) {
                      logger$1.setLogInfo("Payment Data Filled: New Payment Method", undefined, "PAYMENT_DATA_FILLED", undefined, undefined, undefined, undefined, "APPLE_PAY");
                      logger$1.setLogInfo("TrustPay ApplePay Success Response", JSON.stringify(res), "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
                      var msg = Object.fromEntries([[
                              "applePaySyncPayment",
                              true
                            ]]);
                      Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
                      return Promise.resolve();
                    }), (function (err) {
                    var exceptionMessage = JSON.stringify(Utils$OrcaPaymentPage.formatException(err));
                    logger$1.setLogInfo(exceptionMessage, undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
                    var msg = Object.fromEntries([[
                            "applePaySyncPayment",
                            true
                          ]]);
                    Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
                    return Promise.resolve();
                  }));
            return ;
          }
          catch (raw_exn){
            var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
            logger$1.setLogInfo(JSON.stringify(Utils$OrcaPaymentPage.formatException(exn)), undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
            var msg = Object.fromEntries([[
                    "applePaySyncPayment",
                    true
                  ]]);
            return Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
          }
        };
        var handlePollStatusMessage = function (ev) {
          var eventDataObject = ev.data;
          var headers = [
            [
              "Content-Type",
              "application/json"
            ],
            [
              "api-key",
              publishableKey
            ]
          ];
          var obj = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "confirmParams");
          if (obj !== undefined) {
            redirect.contents = Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(obj), "redirect", "if_required");
          }
          var val = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "poll_status");
          if (val !== undefined) {
            Utils$OrcaPaymentPage.messageCurrentWindow(undefined, [
                  [
                    "fullscreen",
                    true
                  ],
                  [
                    "param",
                    "paymentloader"
                  ],
                  [
                    "iframeId",
                    selectorString
                  ]
                ]);
            var dict = Utils$OrcaPaymentPage.getDictFromJson(val);
            var pollId = Utils$OrcaPaymentPage.getString(dict, "poll_id", "");
            var interval = Math.imul(Core__Option.getOr(Core__Int.fromString(undefined, Utils$OrcaPaymentPage.getString(dict, "delay_in_secs", "")), 1), 1000);
            var count = Core__Option.getOr(Core__Int.fromString(undefined, Utils$OrcaPaymentPage.getString(dict, "frequency", "")), 5);
            var url = Utils$OrcaPaymentPage.getString(dict, "return_url_with_query_params", "");
            Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.pollStatus(headers, customPodUri, pollId, interval, count, url, logger$1).then(function (param) {
                      return Promise.resolve((Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.retrievePaymentIntent(clientSecret$1, headers, logger$1, customPodUri, true).then(function (json) {
                                            if (redirect.contents === "always") {
                                              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                                              var status = Utils$OrcaPaymentPage.getString(dict, "status", "");
                                              var returnUrl = Utils$OrcaPaymentPage.getString(dict, "return_url", "");
                                              Window$OrcaPaymentPage.replaceRootHref(returnUrl + "?payment_intent_client_secret=" + clientSecret$1 + "&status=" + status);
                                              return Promise.resolve(null);
                                            }
                                            Utils$OrcaPaymentPage.messageCurrentWindow(undefined, [
                                                  [
                                                    "fullscreen",
                                                    false
                                                  ],
                                                  [
                                                    "submitSuccessful",
                                                    true
                                                  ],
                                                  [
                                                    "data",
                                                    json
                                                  ]
                                                ]);
                                            return Promise.resolve(json);
                                          }), (function (err) {
                                          if (redirect.contents === "always") {
                                            Window$OrcaPaymentPage.replaceRootHref(url);
                                          }
                                          Utils$OrcaPaymentPage.messageCurrentWindow(undefined, [
                                                [
                                                  "submitSuccessful",
                                                  false
                                                ],
                                                [
                                                  "error",
                                                  err
                                                ]
                                              ]);
                                          return Promise.resolve(err);
                                        })), undefined));
                    }), (function (e) {
                    return Promise.resolve((console.log("POLL_STATUS ERROR -", e), undefined));
                  }));
          }
          var val$1 = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "openurl_if_required");
          if (val$1 !== undefined) {
            Utils$OrcaPaymentPage.messageCurrentWindow(undefined, [
                  [
                    "fullscreen",
                    true
                  ],
                  [
                    "param",
                    "paymentloader"
                  ],
                  [
                    "iframeId",
                    selectorString
                  ]
                ]);
            if (redirect.contents === "always") {
              window.location.replace(Core__Option.getOr(Core__JSON.Decode.string(val$1), ""));
              Promise.resolve(null);
            } else {
              Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.retrievePaymentIntent(clientSecret$1, headers, logger$1, customPodUri, true).then(function (json) {
                          Utils$OrcaPaymentPage.messageCurrentWindow(undefined, [
                                [
                                  "submitSuccessful",
                                  true
                                ],
                                [
                                  "data",
                                  json
                                ]
                              ]);
                          return Promise.resolve(json);
                        }), (function (err) {
                        Utils$OrcaPaymentPage.messageCurrentWindow(undefined, [
                              [
                                "submitSuccessful",
                                false
                              ],
                              [
                                "error",
                                err
                              ]
                            ]);
                        return Promise.resolve(err);
                      })).finally(function () {
                    Utils$OrcaPaymentPage.messageCurrentWindow(undefined, [[
                            "fullscreen",
                            false
                          ]]);
                  });
            }
            return ;
          }
          
        };
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleApplePayMounted, "onApplePayMount");
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handlePollStatusMessage, "onPollStatusMsg");
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleGooglePayThirdPartyFlow, "onGooglePayThirdParty");
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleApplePayThirdPartyFlow, "onApplePayThirdParty");
        var fetchSessionTokens = function (mountedIframeRef) {
          var handleSessionTokensLoaded = function ($$event) {
            var json = $$event.data;
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            var sessionTokensData = Utils$OrcaPaymentPage.getString(dict, "data", "") === "session_tokens";
            if (!sessionTokensData) {
              return ;
            }
            var json$1 = Utils$OrcaPaymentPage.getJsonFromDict(dict, "response", null);
            var sessionsArr = SessionsType$OrcaPaymentPage.getSessionsTokenJson(Core__Option.getOr(Core__JSON.Decode.object(json$1), {}), "session_token");
            var applePayPresent = sessionsArr.find(function (item) {
                  var x = Core__Option.getOr(Belt_Option.flatMap(Belt_Option.flatMap(Core__JSON.Decode.object(item), (function (x) {
                                  return x["wallet_name"];
                                })), Core__JSON.Decode.string), "");
                  if (x === "apple_pay") {
                    return true;
                  } else {
                    return x === "applepay";
                  }
                });
            if (!Core__Option.isSome(applePayPresent)) {
              var msg = Object.fromEntries([[
                      "applePaySessionObjNotPresent",
                      true
                    ]]);
              Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
            }
            var googlePayPresent = sessionsArr.find(function (item) {
                  var x = Core__Option.getOr(Belt_Option.flatMap(Belt_Option.flatMap(Core__JSON.Decode.object(item), (function (x) {
                                  return x["wallet_name"];
                                })), Core__JSON.Decode.string), "");
                  if (x === "google_pay") {
                    return true;
                  } else {
                    return x === "googlepay";
                  }
                });
            Promise.resolve([
                      json$1,
                      applePayPresent,
                      googlePayPresent
                    ]).then(function (res) {
                    var applePayPresent = res[1];
                    var json = res[0];
                    if (Utils$OrcaPaymentPage.getIsComponentTypeForPaymentElementCreate(componentType) && Core__Option.isSome(applePayPresent)) {
                      var handleApplePayMessages = function (applePayEvent) {
                        var json = applePayEvent.data;
                        var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                        var match = dict["applePayButtonClicked"];
                        var match$1 = dict["applePayPaymentRequest"];
                        var match$2 = Core__Option.getOr(Core__Option.flatMap(dict["isTaxCalculationEnabled"], Core__JSON.Decode.bool), false);
                        if (match === undefined) {
                          return ;
                        }
                        if (match$1 === undefined) {
                          return ;
                        }
                        if (!Core__Option.getOr(Core__JSON.Decode.bool(match), false)) {
                          return ;
                        }
                        var isDelayedSessionToken = Core__Option.getOr(Core__JSON.Decode.bool(Core__Option.getOr(Core__Option.getOr(Belt_Option.flatMap(applePayPresent, Core__JSON.Decode.object), {})["delayed_session_token"], null)), false);
                        if (isDelayedSessionToken) {
                          return ;
                        }
                        logger$1.setLogInfo("Normal Session Token Flow", undefined, "APPLE_PAY_FLOW", undefined, undefined, undefined, undefined, "APPLE_PAY");
                        var callBackFunc = function (payment) {
                          var msg = Object.fromEntries([
                                [
                                  "applePayProcessPayment",
                                  payment.token
                                ],
                                [
                                  "applePayBillingContact",
                                  payment.billingContact
                                ],
                                [
                                  "applePayShippingContact",
                                  payment.shippingContact
                                ]
                              ]);
                          Window$OrcaPaymentPage.sendPostMessage(applePayEvent.source, msg);
                        };
                        ApplePayHelpers$OrcaPaymentPage.startApplePaySession(match$1, applePaySessionRef, applePayPresent, logger$1, Caml_option.some(applePayEvent), callBackFunc, undefined, clientSecret$1, publishableKey, match$2);
                      };
                      EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleApplePayMessages, "onApplePayMessages");
                    }
                    if (Utils$OrcaPaymentPage.getIsComponentTypeForPaymentElementCreate(componentType) && Core__Option.isSome(res[2]) && wallets.googlePay === "Auto") {
                      var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                      var sessionObj = SessionsType$OrcaPaymentPage.itemToObjMapper(dict, "Others");
                      var gPayToken = SessionsType$OrcaPaymentPage.getPaymentSessionObj(sessionObj.sessionsToken, "Gpay");
                      var tokenObj;
                      switch (gPayToken.TAG) {
                        case "ApplePayTokenOptional" :
                        case "GooglePayThirdPartyTokenOptional" :
                            tokenObj = SessionsType$OrcaPaymentPage.defaultToken;
                            break;
                        case "OtherTokenOptional" :
                            tokenObj = gPayToken._0;
                            break;
                        
                      }
                      var gpayobj = tokenObj !== undefined ? tokenObj : SessionsType$OrcaPaymentPage.defaultToken;
                      var payRequest = Object.assign({}, GooglePayType$OrcaPaymentPage.baseRequest, {
                            allowedPaymentMethods: Utils$OrcaPaymentPage.arrayJsonToCamelCase(gpayobj.allowed_payment_methods)
                          });
                      try {
                        var gPayClient = new google.payments.api.PaymentsClient({
                              environment: publishableKey.startsWith("pk_prd_") ? "PRODUCTION" : "TEST"
                            });
                        Core__Promise.$$catch(gPayClient.isReadyToPay(payRequest).then(function (res) {
                                  var dict = Utils$OrcaPaymentPage.getDictFromJson(res);
                                  var isReadyToPay = Utils$OrcaPaymentPage.getBool(dict, "result", false);
                                  var msg = Object.fromEntries([[
                                          "isReadyToPay",
                                          isReadyToPay
                                        ]]);
                                  Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                                  return Promise.resolve();
                                }), (function (err) {
                                logger$1.setLogInfo(JSON.stringify(err), undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "DEBUG", undefined, "GOOGLE_PAY");
                                return Promise.resolve();
                              }));
                        var handleGooglePayMessages = function ($$event) {
                          var evJson = $$event.data;
                          var gpayClicked = Utils$OrcaPaymentPage.getBoolFromOptionalJson(Utils$OrcaPaymentPage.getOptionalJsonFromJson(evJson, "GpayClicked"), false);
                          var paymentDataRequest = Core__Option.getOr(Utils$OrcaPaymentPage.getOptionalJsonFromJson(evJson, "GpayPaymentDataRequest"), null);
                          if (gpayClicked && paymentDataRequest !== null) {
                            setTimeout((function () {
                                    Core__Promise.$$catch(gPayClient.loadPaymentData(paymentDataRequest).then(function (json) {
                                              var msg = Object.fromEntries([[
                                                      "gpayResponse",
                                                      json
                                                    ]]);
                                              Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
                                              logger$1.setLogInfo("Payment Data Filled: New Payment Method", undefined, "PAYMENT_DATA_FILLED", undefined, undefined, undefined, undefined, "GOOGLE_PAY");
                                              return Promise.resolve();
                                            }), (function (err) {
                                            logger$1.setLogInfo(JSON.stringify(err), undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "DEBUG", undefined, "GOOGLE_PAY");
                                            var msg = Object.fromEntries([[
                                                    "gpayError",
                                                    err
                                                  ]]);
                                            Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
                                            return Promise.resolve();
                                          }));
                                  }), 0);
                            return ;
                          }
                          
                        };
                        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleGooglePayMessages, "onGooglePayMessages");
                      }
                      catch (exn){
                        console.log("Error loading Gpay");
                      }
                    } else if (wallets.googlePay === "Never") {
                      logger$1.setLogInfo("GooglePay is set as never by merchant", undefined, "GOOGLE_PAY_FLOW", undefined, undefined, "INFO", undefined, "GOOGLE_PAY");
                    }
                    return Promise.resolve(json);
                  }).then(function (json) {
                  var msg = Object.fromEntries([[
                          "sessions",
                          json
                        ]]);
                  Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                  return Promise.resolve(json);
                });
          };
          var msg = Object.fromEntries([[
                  "sendSessionTokensResponse",
                  true
                ]]);
          EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleSessionTokensLoaded, "onSessionTokensLoaded-" + componentType);
          Window$OrcaPaymentPage.iframePostMessage(preMountLoaderIframeDiv, msg);
        };
        preMountLoaderMountedPromise.then(function (param) {
              fetchPaymentsList(mountedIframeRef, componentType);
              var disableSavedPaymentMethods = Utils$OrcaPaymentPage.getBool(Utils$OrcaPaymentPage.getDictFromJson(newOptions), "displaySavedPaymentMethods", true) && Utils$OrcaPaymentPage.spmComponents.includes(componentType);
              fetchCustomerPaymentMethods(mountedIframeRef, disableSavedPaymentMethods, componentType);
              fetchSessionTokens(mountedIframeRef);
              return Promise.resolve();
            });
        Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, message);
      };
      var paymentElement = LoaderPaymentElement$OrcaPaymentPage.make(componentType, newOptions, setElementIframeRef, iframeRef, mountPostMessage, undefined);
      savedPaymentElement[componentType] = paymentElement;
      return paymentElement;
    };
    return {
            getElement: getElement,
            update: update,
            fetchUpdates: fetchUpdates,
            create: create
          };
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    Sentry$OrcaPaymentPage.captureException(e);
    return Types$OrcaPaymentPage.defaultElement;
  }
}

export {
  make ,
}
/* Utils-OrcaPaymentPage Not a pure module */
