// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "./PaymentHelpers.bs.js";

function taxResponseToObjMapper(dict) {
  return {
          payment_id: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
          net_amount: Utils$OrcaPaymentPage.getInt(dict, "net_amount", 0),
          order_tax_amount: Utils$OrcaPaymentPage.getInt(dict, "order_tax_amount", 0),
          shipping_cost: Utils$OrcaPaymentPage.getInt(dict, "shipping_cost", 0)
        };
}

function calculateTax(shippingAddress, logger, clientSecret, publishableKey, paymentMethodType) {
  return PaymentHelpers$OrcaPaymentPage.calculateTax(publishableKey, Utils$OrcaPaymentPage.getPaymentId(clientSecret), clientSecret, paymentMethodType, shippingAddress, logger, "");
}

export {
  taxResponseToObjMapper ,
  calculateTax ,
}
/* Utils-OrcaPaymentPage Not a pure module */
